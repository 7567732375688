<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <circle class="a" cx="12" cy="12" r="12" />
    <g transform="translate(-52 -332)">
      <path class="b" d="M-.5,0h3L2,9H0Z" transform="translate(63 337)" />
      <circle class="b" cx="1.5" cy="1.5" r="1.5" transform="translate(62.5 348)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Warning"
};
</script>

<style lang="scss" scoped>
.a {
  fill: #ffb400;
}
.b {
  fill: #fff;
}
</style>