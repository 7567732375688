<template>
  <div class="GroupEditor" v-if="!loading">
    <TjingHorizontalNotification
      color="sunrise"
      class="mb-4"
      v-if="isEventReallyStarted == false && waitinglistLength > 0"
    >
      <Warning />
      <p class="information">Players on waiting list</p>
      <TjingClickToolTip>
        <p>The waiting list can be found below, after the groups.</p>
      </TjingClickToolTip>
    </TjingHorizontalNotification>
    <main>
      <header>
        <h4>Manage groups</h4>
        <TjingClickToolTip>
          <div class="how-to">
            <p>
              <b>To move players:</b> Select a player’s block and then an empty
              spot in a group.
            </p>
            <p>
              <b>To swap players:</b> Select a player’s block and then another
              player’s block.
            </p>
            <p>
              <b>To move or swap entire groups:</b> Select a group header and
              then another group header.
            </p>
          </div>
        </TjingClickToolTip>
      </header>
      <section class="main">
        <template v-for="(pool, poolIndex) in managedRound.pools">
          <div
            class="pool"
            :class="poolName(poolIndex)"
            v-if="pool.groups.length > 0"
            :key="pool.id"
          >
            <div class="pool-header">
              <h3 class="round-pool-title">
                Round {{ roundNumber }}
                <span v-if="managedRound.pools.length > 1">
                  - Pool {{ poolName(poolIndex) }}</span
                >
              </h3>
              <StandardToggleButton
                v-if="pool.status == 'CLOSED' || pool.status == 'PREPARE'"
                title="Options"
                :active="pool.showOptions"
                @click="pool.showOptions = !pool.showOptions"
              />
            </div>

            <div class="pool-utility">
              <p>
                <b
                  >{{ groupsUsed(pool) }}/{{ pool.groups.length }} Groups
                  used</b
                >
                ({{ playersInPool(pool) }}
                Players)
              </p>
            </div>
            <slide-up-down :active="pool.showOptions" :duration="300">
              <div class="utility-container">
                <p class="large extra-padding">Quick actions</p>

                <div class="change-start-hole">
                  <p><b>Set start hole for all groups</b></p>
                  <select
                    v-model="pool.defaultNewStartHole"
                    class="newstarthole"
                  >
                    <option
                      :value="hole.id"
                      v-for="hole in pool.layoutVersion.holes"
                      :key="hole.id"
                    >
                      {{ holeDisplayName(hole) }}
                    </option>
                  </select>

                  <StandardSolidButton
                    title="Set"
                    :fluid="true"
                    :locked="newStartHoleLoading"
                    :desktopFluidity="true"
                    @click="
                      updateStartHoles(pool.defaultNewStartHole, pool.groups)
                    "
                  />
                </div>
                <div class="auto-tee-times">
                  <p class="auto-title">
                    <b>Set start time increments and order between groups</b>
                  </p>
                  <p class="auto-sub-title">
                    Times are created from the current round time.
                  </p>
                  <div class="sorting-options">
                    <div class="increment-options">
                      <p class="increments-title"><b>Time increments</b></p>
                      <ValueSelector
                        :values="teeTimeOptions().values"
                        :colors="teeTimeOptions().colors"
                        :title="''"
                        @updatedValue="teeTimeInverval = $event.value"
                      />
                    </div>
                    <div class="options">
                      <p class="time-title"><b>Time order</b></p>
                      <DefaultRadioButton
                        :groupname="pool.id + 'time-options'"
                        :value="pool.teeTimeSortOrder"
                        @change="pool.teeTimeSortOrder = $event"
                        :options="{
                          title: 'Earliest time first',
                          value: 'asc',
                          description: '',
                        }"
                      />
                      <DefaultRadioButton
                        :groupname="pool.id + 'time-options'"
                        :value="pool.teeTimeSortOrder"
                        @change="pool.teeTimeSortOrder = $event"
                        :options="{
                          title: 'Latest time first',
                          value: 'desc',
                          description: '',
                        }"
                      />
                    </div>

                    <StandardSolidButton
                      title="Set"
                      :fluid="true"
                      :locked="setTeeTimesLoading"
                      :desktopFluidity="true"
                      @click="applyTeeTimes(pool)"
                    />
                  </div>
                </div>

                <div class="delete-groups-container">
                  <p class="large">Delete groups</p>
                  <p class="delete-sub-title">
                    <b
                      >Delete the groups when you want to do either of the
                      following:
                    </b>
                  </p>
                  <ul>
                    <li>Remove all players from groups and re-sort.</li>
                    <li>Change the course layout.</li>
                  </ul>
                  <p
                    class="delete-groups"
                    v-if="pool.status == 'CLOSED' || pool.status == 'PREPARE'"
                    @click="
                      prepareToDeleteGroups(
                        pool,
                        roundNumber,
                        poolName(poolIndex)
                      )
                    "
                  >
                    Delete groups
                  </p>
                </div>
              </div>
            </slide-up-down>

            <div class="groups-container">
              <StandardEditableGroup
                v-for="group in pool.groups"
                :key="group.id"
                :group="group"
                :pool="pool"
                :startMethod="pool.startMethod"
                :selectedPlayer="selectedPlayer"
                :selectedGroup="selectedGroup"
                :roundNumber="roundNumber"
                :allowStartTime="allowStartTime"
                :isResultSigningEnabled="eventData.isResultSigningEnabled"
                :isEventReallyStarted="isEventReallyStarted"
                @selectPlayer="selectPlayer($event)"
                @emptySpotClicked="emptySpotClicked($event, group)"
                @swapPlayers="swapPlayers($event)"
                @refresh="loadData()"
                @select-group="selectGroup($event, pool)"
              />
              <div
                class="add-group"
                v-if="pool.status == 'PREPARE' || pool.status == 'CLOSED'"
              >
                <button @click="createGroup(pool)">Add new group</button>
              </div>
            </div>
          </div>
        </template>
      </section>

      <ThePlayersWithoutAGroup
        class="players-without-group"
        @playerSelected="playerWithoutGroupSelected($event)"
        :eventData="eventData"
        :managedRound="managedRound"
        :roundNumber="roundNumber"
      />

      <GroupEditorWaitingList
        class="the-waitinglist"
        v-if="roundNumber == 1"
        @playerInWaitingSelected="playerInWaitingSelected($event)"
        :eventData="eventData"
      />
      <section class="before-done"></section>
      <section class="done-section">
        <StandardSolidButton
          title="Done"
          :fluid="false"
          :desktopFluidity="true"
          @click="$router.push({ name: 'event-rounds' })"
        />
      </section>
      <section class="after-done"></section>
      <sweet-modal
        :enable-mobile-fullscreen="false"
        blocking
        ref="addtoopengroup"
        hide-close-button
      >
        <template
          v-if="
            selectedPlayerWithoutGroup != null ||
            selectedPlayerInWaitingList != null ||
            selectedPlayer != null
          "
        >
          <h3>
            Add
            {{ selectedPlayerFirstName }}
            to this group?
          </h3>
          <p>
            This group has started. Once added you will not be able to move them
            to a different group in this round.
          </p>

          <div class="buttons">
            <StandardSolidButton
              title="Cancel"
              :fluid="false"
              @click="$refs.addtoopengroup.close()"
            />
            <StandardBorderedButton
              v-if="selectedPlayerInWaitingList != null"
              title="Add player"
              :fluid="false"
              @click="
                makePlayerInGroup(selectedPlayerInWaitingList.id, targetGroup)
              "
            />
            <StandardBorderedButton
              v-else-if="selectedPlayerWithoutGroup != null"
              title="Add player"
              :fluid="false"
              @click="
                addPlayerToGroup(
                  selectedPlayerWithoutGroup.id,
                  targetGroup,
                  true
                )
              "
            />
            <StandardBorderedButton
              v-else
              title="Add player"
              :fluid="false"
              @click="moveToOpenGroup(selectedPlayer.id, targetGroup, true)"
            />
          </div>
        </template>
      </sweet-modal>
      <sweet-modal
        :enable-mobile-fullscreen="false"
        blocking
        ref="deletegroups"
        hide-close-button
      >
        <template v-if="poolToAction != null">
          <h3>
            Delete groups for Round {{ poolToAction.roundNumber
            }}{{
              managedRound.pools.length > 1
                ? ` - Pool ${poolToAction.poolLetter}`
                : ""
            }}?
          </h3>
          <p v-if="poolToAction">
            Deleting the groups allows you to change the course layout
            (optional) and create the groups again.
          </p>

          <div class="buttons">
            <StandardSolidButton
              title="Cancel"
              :fluid="false"
              @click="$refs.deletegroups.close()"
            />
            <StandardBorderedButton
              title="Delete"
              :fluid="false"
              @click="deleteGroups(poolToAction, false)"
            />
          </div>
        </template>
      </sweet-modal>
    </main>
  </div>
</template>


<script>
import moment from "moment";
import StandardEditableGroup from "@/components/StandardEditableGroup";
import GroupEditorWaitingList from "@/components/GroupEditorWaitingList";
import ThePlayersWithoutAGroup from "@/components/ThePlayersWithoutAGroup";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardToggleButton from "@/components/UIElements/StandardToggleButton";
import TjingClickToolTip from "@/components/UIElements/TjingClickToolTip";
import Warning from "@/assets/icons/Warning";
import TjingHorizontalNotification from "@/components/TjingHorizontalNotification";
import SlideUpDown from "vue-slide-up-down";
import ValueSelector from "@/components/ValueSelector";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";

export default {
  name: "GroupEditor",
  components: {
    StandardEditableGroup,
    GroupEditorWaitingList,
    ThePlayersWithoutAGroup,
    TjingClickToolTip,
    Warning,
    TjingHorizontalNotification,
    StandardSolidButton,
    StandardBorderedButton,
    SlideUpDown,
    ValueSelector,
    StandardToggleButton,
    DefaultRadioButton,
  },
  data() {
    return {
      eventData: {},
      loading: true,
      selectedPlayer: null,
      selectedGroup: null,
      targetGroup: null,
      selectedPlayerInWaitingList: null,
      selectedPlayerWithoutGroup: null,
      poolToAction: null,
      newStartHoleLoading: false,
      teeTimeInverval: 10,
      setTeeTimesLoading: false,
      clickedGroup: null,
      autoTeeTimesOrder: "asc",
    };
  },
  computed: {
    selectedPlayerFirstName() {
      return (
        this.selectedPlayer?.player?.user.firstName ||
        this.selectedPlayerInWaitingList?.user.firstName ||
        this.selectedPlayerWithoutGroup?.user.firstName ||
        null
      );
    },
    numberOfPoolsToManage() {
      let count = 0;

      this.managedRound.pools.forEach((pool) => {
        if (pool.groups.length > 0) count++;
      });

      return count;
    },
    allowStartTime() {
      let bool = false;
      this.managedRound.pools.forEach((pool) => {
        pool.groups.forEach((group) => {
          if (group.startsAt != null) {
            bool = true;
          }
        });
      });
      return bool;
    },
    isEventReallyStarted() {
      let started = false;

      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          if (pool.status == "OPEN" || pool.status == "COMPLETED") {
            started = true;
          }
        });
      });
      return started;
    },
    waitinglistLength() {
      return this.eventData.registrations.filter((registration) => {
        return registration.status == "PENDING";
      }).length;
    },
    roundNumber() {
      let roundNumber = 0;

      this.eventData.rounds.forEach((round, index) => {
        if (round.id == this.managedRound.id) {
          roundNumber = index + 1;
        }
      });

      return roundNumber;
    },
    prepareAgainOptionAvailable() {
      let shown = false;

      if (
        this.roundNumber == 1 &&
        this.eventData.rounds[0].status != "COMPLETED"
      ) {
        shown = true;

        this.managedRound.pools.forEach((pool) => {
          if (pool.status != "PREPARE") {
            shown = false;
          }
        });
      } else {
        this.managedRound.pools.forEach((pool) => {
          if (pool.status == "PREPARE") {
            shown = true;
          }
        });
      }

      return shown;
    },
    managedRound() {
      return this.eventData.rounds.filter((round) => {
        return round.id == this.$router.currentRoute.params.roundId;
      })[0];
    },
  },
  methods: {
    async applyTeeTimes(pool) {
      let teeTime = moment(pool.date);
      const timeInterval = this.teeTimeInverval;
      this.setTeeTimesLoading = true;

      try {
        const groups =
          pool.teeTimeSortOrder == "asc"
            ? pool.groups
            : pool.groups.slice().reverse();

        for (let index = 0; index < groups.length; index++) {
          const group = groups[index];
          const time = teeTime
            .add(index > 0 ? timeInterval : 0, "minutes")
            .toISOString();

          let groupStartHole = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation UpdateGroup($UpdateGroup: UpdateGroupInput!){
                  UpdateGroup(groupId:"${group.id}",input:$UpdateGroup){
                    id
                    startsAt
                  }
                }
              `,
              variables: {
                UpdateGroup: {
                  startsAt: time,
                },
              },
            },
          });

          if (groupStartHole.data.errors) {
            throw "Error";
          }
        }
        this.setTeeTimesLoading = false;
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Time increments set",
          type: "success",
        });
        this.utilitiesShown = false;
        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    teeTimeOptions() {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 1; index <= 120; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default: index == 10,
          text: "mins",
        });
      }

      return options;
    },
    async updateStartHoles(holeId, groups) {
      this.newStartHoleLoading = true;
      try {
        for (let index = 0; index < groups.length; index++) {
          const group = groups[index];

          let groupStartHole = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation UpdateGroup($UpdateGroup: UpdateGroupInput!){
                  UpdateGroup(groupId:"${group.id}",input:$UpdateGroup){
                    id
                    startsAt
                  }
                }
              `,
              variables: {
                UpdateGroup: {
                  startHoleId: holeId,
                },
              },
            },
          });
        }

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Start hole set",
          type: "success",
        });
        this.newStartHoleLoading = false;
        this.utilitiesShown = false;
        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },

    holeDisplayName(hole) {
      if (hole.name != null) {
        return hole.name;
      } else {
        return hole.number;
      }
    },
    prepareToDeleteGroups(pool, roundNumber, poolLetter) {
      this.poolToAction = {
        pool,
        roundNumber,
        poolLetter,
      };

      this.$refs.deletegroups.open();
    },
    groupsUsed(pool) {
      let groupsUsedCount = 0;

      pool.groups.forEach((group) => {
        if (group.playerConnections.length > 0) groupsUsedCount++;
      });

      return groupsUsedCount;
    },
    async deleteGroups(poolToAction, deleteAllGroupsInRound) {
      const round = this.managedRound;
      const pool = poolToAction.pool;

      let deleteQuery = "";

      if (deleteAllGroupsInRound) {
        round.pools.forEach((pool, index) => {
          let query = `
        Pool${index}: DeleteGroups(poolId:"${pool.id}"){
          id
        }`;

          deleteQuery += query;
        });
      } else {
        deleteQuery = `
        DeleteGroups(poolId:"${pool.id}"){
          id
        }`;
      }

      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation {
            ${deleteQuery}
          }
          `,
          },
        });

        if (this.numberOfPoolsToManage == 1) {
          this.$router.push({ name: "event-rounds" });
        } else {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Groups deleted",
            type: "success",
          });
          this.loadData();
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
      this.poolToAction = null;
      this.$refs.deletegroups.close();
    },
    prepareAgain() {
      this.$router.push({
        name: "event-rounds",
        query: {
          sort: true,
          roundId: this.$router.currentRoute.params.roundId,
        },
      });
    },
    async createGroup(pool) {
      try {
        let newGroup = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              mutation CreateGroup($CreateGroup: GroupInput!){
                CreateGroup(poolId:"${pool.id}",input:$CreateGroup){
                  id
                }
              }
              `,
            variables: {
              CreateGroup: {
                startHoleId: pool.groups[0].startHole.id,
              },
            },
          },
        });

        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    playerInWaitingSelected(player) {
      this.selectedPlayerInWaitingList = player;
    },
    playerWithoutGroupSelected(player) {
      this.selectedPlayerWithoutGroup = player;
    },
    async selectGroup(group, pool) {
      if (pool.status == "OPEN") {
        this.selectedGroup = null;
      } else if (group == null || group.status != "CLOSED") {
        this.selectedGroup = null;
      } else if (
        this.selectedGroup != null &&
        group.id == this.selectedGroup.id
      ) {
        this.selectedGroup = null;
      } else if (this.selectedGroup == null) {
        this.selectedGroup = group;
        this.selectedPlayer = null;
      } else {
        let groupOneId = this.selectedGroup.id;
        let groupTwoId = group.id;

        let queryString = "";

        for (let i = 0; i < this.selectedGroup.playerConnections.length; i++) {
          queryString += ` gROne_${i}: RemovePlayerFromGroup(groupId:"${groupOneId}",playerId:"${this.selectedGroup.playerConnections[i].player.id}"){ id } `;
        }
        for (let ii = 0; ii < group.playerConnections.length; ii++) {
          queryString += ` gRTwo_${ii}: RemovePlayerFromGroup(groupId:"${groupTwoId}",playerId:"${group.playerConnections[ii].player.id}"){ id } `;
        }
        for (let j = 0; j < this.selectedGroup.playerConnections.length; j++) {
          queryString += ` gAOne_${j}: AddPlayerToGroup(groupId:"${groupTwoId}",playerId:"${this.selectedGroup.playerConnections[j].player.id}"){ id } `;
        }
        for (let jj = 0; jj < group.playerConnections.length; jj++) {
          queryString += ` gATwo_${jj}: AddPlayerToGroup(groupId:"${groupOneId}",playerId:"${group.playerConnections[jj].player.id}"){ id } `;
        }

        try {
          let swap = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation {
                  ${queryString}
                }
              `,
            },
          });

          this.loadData();
        } catch (err) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        }
      }
    },
    async swapPlayers(playerTwo) {
      let playerOneId = this.selectedPlayer.player.id;
      let playerOneGroupId = this.selectedPlayer.groupId;
      let playerTwoId = playerTwo.player.id;
      let playerTwoGroupId = playerTwo.groupId;

      let removePlayerOne = await this.removePlayerFromGroup(
        playerOneId,
        playerOneGroupId
      );

      let removePlayerTwo = await this.removePlayerFromGroup(
        playerTwoId,
        playerTwoGroupId
      );

      let addPlayerOne = await this.addPlayerToGroup(
        playerOneId,
        playerTwoGroupId
      );
      let addPlayerTwo = await this.addPlayerToGroup(
        playerTwoId,
        playerOneGroupId
      );
      (this.selectedPlayer = null), (this.targetGroup = null);
      this.loadData();
    },
    async moveToOpenGroup() {
      await this.removePlayerFromGroup(
        this.selectedPlayer.player.id,
        this.selectedPlayer.groupId
      );
      await this.addPlayerToGroup(
        this.selectedPlayer.player.id,
        this.targetGroup,
        true
      );
    },
    async emptySpotClicked(groupId) {
      this.targetGroup = groupId;
      const clickedGroup = this.findGroupById(this.managedRound, groupId);

      if (
        this.selectedPlayer != null &&
        this.selectedPlayer.groupId != groupId
      ) {
        if (clickedGroup?.status == "OPEN") {
          this.$refs.addtoopengroup.open();
        } else {
          let removePlayer = await this.removePlayerFromGroup(
            this.selectedPlayer.player.id,
            this.selectedPlayer.groupId
          );
          let addPlayer = await this.addPlayerToGroup(
            this.selectedPlayer.player.id,
            groupId,
            true
          );
        }
      } else if (this.selectedPlayerWithoutGroup != null) {
        if (clickedGroup?.status == "OPEN") {
          this.$refs.addtoopengroup.open();
        } else {
          this.addPlayerToGroup(
            this.selectedPlayerWithoutGroup.id,
            groupId,
            true
          );
        }
      } else if (this.selectedPlayerInWaitingList != null) {
        if (clickedGroup?.status == "OPEN") {
          this.$refs.addtoopengroup.open();
        } else {
          this.makePlayerInGroup(this.selectedPlayerInWaitingList.id, groupId);
        }
      }
    },
    findGroupById(round, groupId) {
      let targetGroup = null;

      round.pools.forEach((pool) => {
        pool.groups.forEach((group) => {
          if (group.id == groupId) {
            targetGroup = group;
          }
        });
      });

      return targetGroup;
    },
    async makePlayerInGroup(registrationId, groupId) {
      try {
        let updatedPlayer = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
              mutation{
                UpdateRegistrationStatus(registrationId:"${registrationId}",status:ACCEPTED){
                  player{
                    id
                  }
                }
              }
              `,
          },
        });

        const playerId =
          updatedPlayer.data.data.UpdateRegistrationStatus.player.id;
        await this.addPlayerToGroup(playerId, groupId, true);
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error, unable to move player",
          type: "error",
        });
      }
    },
    async removePlayerFromGroup(playerId, groupId) {
      try {
        let removePlayer = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation {
            RemovePlayerFromGroup(groupId:"${groupId}", playerId:"${playerId}"){
              id
            }
          }
          `,
          },
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async addPlayerToGroup(playerId, targetGroupId, refresh) {
      try {
        const addPlayer = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation {
            AddPlayerToGroup(groupId:"${targetGroupId}", playerId:"${playerId}"){
              id
            }
          }
          `,
          },
        });

        this.$refs.addtoopengroup.close();
        this.targetGroup = null;

        if (refresh) {
          this.loadData();
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    selectPlayer(player) {
      this.selectedPlayer = player;
      this.selectedGroup = null;
    },
    playersInPool(pool) {
      let playersCount = 0;

      pool.groups.forEach((group) => {
        playersCount += group.playerConnections.length;
      });

      return playersCount;
    },
    poolName(poolIndex) {
      if (poolIndex == 0) {
        return "A";
      } else if (poolIndex == 1) {
        return "B";
      } else if (poolIndex == 2) {
        return "C";
      } else if (poolIndex == 3) {
        return "D";
      } else if (poolIndex == 4) {
        return "E";
      } else if (poolIndex == 5) {
        return "F";
      }
    },
    async loadData() {
      let event = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              tour{
                registrationMethod
                divisions{
                  type
                  name
                  id
                }
              }
              isResultSigningEnabled
              players{
                id
                division{
                  type
                }
                user{
                  id
                  firstName
                  lastName
                  profile{
                    pdgaNumber
                    pdgaRating
                  }
                }
                dnf{
                  isDnf
                }
                dns{
                  isDns
                }
              }
              registrations{
                id
                status
                hasPaid
                createdAt
                division{
                  type
                  name
                  id
                }
                user{
                  id
                  firstName
                  lastName
                  profile{
                    pdgaNumber
                    pdgaRating
                  }
                }
                player{
                  id
                }
              }
              rounds{
                id
                status
                pools{
                  id
                  position
                  status
                  date
                  isDeletable
                  areGroupsPublic
                  startMethod
                  groups{
                    id
                    status
                    position
                    startsAt
                    startHole{
                      id
                      number
                      name
                    }
                    marshall{
                      id
                      firstName
                      lastName
                    }
                    playerConnections{
                      hasSignedResult
                      id
                      position
                      groupId
                      player{
                        id
                        isWildcard
                        hasPaid
                        division{
                          id
                          type
                          name
                        }
                        dnf{
                          isDnf
                        }
                        dns{
                          isDns
                        }
                        user{
                          id
                          firstName
                          lastName
                          profile{
                            pdgaNumber
                            pdgaRating
                            club
                          }
                        }
                      }
                    }
                    id
                    position
                    status
                  }
                  layoutVersion{
                    holes{
                      par
                      id
                      name
                      number
                    }
                    layout{
                      name
                      course{
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
          `,
        },
      });

      this.$store.dispatch(
        "setTourDivisions",
        event.data.data.event.tour.divisions
      );
      this.$store.dispatch("loadManagedEventData", this.$route.params.eventId);
      event.data.data.event.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          pool.defaultNewStartHole = pool.layoutVersion.holes[0].id;
          pool.showOptions = false;
          pool.teeTimeSortOrder = "asc";
        });
      });

      this.eventData = event.data.data.event;
      this.targetGroup = null;
      this.selectedPlayer = null;
      this.selectedGroup = null;
      this.selectedPlayerInWaitingList = null;
      this.selectedPlayerWithoutGroup = null;
      this.loading = false;
    },
  },
  async mounted() {
    await this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>
<style lang="scss">
.how-to {
  max-width: 80vw;
  p {
    display: block;
    margin-bottom: 10px !important;
    b {
      margin-right: 4px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
h4 {
  font-size: 18px;
  @include Gilroy-Bold;
  margin: 0;
}
h5 {
  font-size: 16px;
  @include Gilroy-Bold;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .tooltip-container {
    margin-right: auto;
    margin-left: 8px;
    max-width: 30px;
    z-index: 30;
  }

  p {
    margin: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

header {
  height: 44px;
  background-color: $fog;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: flex-start;
  h4 {
    margin-right: 6px;
  }
}

.players-without-group {
  width: 100%;
  background: #ffe5ef;
  padding: 20px 15px 22px 15px;
}
.the-waitinglist {
  width: 100%;
  background: #fff0ec;
  padding: 20px 15px 22px 15px;
  border-top: 1px solid white;
  border-bottom: 0;
}

.before-done {
  height: 48px;
  background: white;
}
.after-done {
  height: 24px;
  background: white;
}

.done-section {
  padding: 0px 15px 24px 15px;
  position: sticky;
  bottom: 0px;
  z-index: 3;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%
  );
}

.main {
  padding: 0px 15px;
  background: white;
  .pool {
    padding-top: 24px;
    &:not(:first-of-type) {
      border-top: 1px solid $cloud;
    }

    .pool-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }

    .round-pool-title {
      font-size: 24px;
      margin-bottom: 0px;
      span {
        @include Gilroy-Regular;
      }
    }

    .pool-utility {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  .utility-container {
    margin-top: 18px;
    border-radius: 6px;
    border: 2px solid $ocean;

    .large {
      font-size: 16px;

      &.extra-padding {
        padding: 18px 15px 0 15px;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
    }

    .change-start-hole {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $sleet;
      padding: 0 0px 24px 0px;
      margin: 12px 15px 0px 15px;
      p {
        width: 100%;
      }
      .newstarthole {
        width: 100px;
        margin: 12px 0 0 0;
        background-position: calc(100% - 12px);
      }

      .StandardBorderedButton {
        width: 80px;
      }
    }

    .auto-tee-times {
      padding: 18px 15px 24px 15px;
      border-bottom: 1px solid $sleet;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      .options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .auto-sub-title {
        margin-top: 8px;
      }
      .sorting-options {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .increment-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .time-title {
        margin-top: 12px;
      }
      .default-radio-buttons {
        margin-top: 12px;
      }
      .StandardBorderedButton {
        margin-left: auto;
      }
    }

    .selector-container {
      justify-content: flex-start;
    }

    .selector-container,
    .StandardBorderedButton {
      margin-top: 12px;
    }
    .StandardBorderedButton {
      width: 80px;
    }

    .delete-groups-container {
      background: #fff3f8;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 18px 15px 24px 15px;

      .delete-sub-title {
        margin-top: 12px;
      }

      ul {
        padding-left: 16px;
        li {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
  }

  .delete-groups {
    cursor: pointer;
    color: $dusk;
  }
  .how-to {
    max-width: 80vw;
    p {
      display: block;
      margin-bottom: 10px;
      b {
        margin-right: 4px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .groups-container {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.add-group {
  width: calc(50% - 10px);
  margin-bottom: 32px;
  border-radius: 6px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  div {
    background: $fog;
    height: 28px;
    width: 100%;
  }

  button {
    background: $twilight;
    color: white;
    font-size: 16px;
    @include Gilroy-Bold;
    border: 1px solid $twilight;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    border-radius: 6px;
  }
}

.reset {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: 34px;

  .tooltip-styling {
    p {
      max-width: calc(100vw - 80px);
      margin-bottom: 32px;
      font-size: 16px;
      text-align: center;
    }
  }
}

.info-messages {
  margin-top: 32px;
  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;

    p {
      font-size: 14px;
      margin-top: 12px;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .main {
    .groups-container {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .group-container {
    width: calc(33.3% - 30px);
    margin-right: 40px;
    max-width: unset;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

@media (min-width: 1200px) {
  .players-without-group {
    width: 100%;
    background: #ffe5ef;
    padding: 30px;
  }
  .the-waitinglist {
    width: 100%;
    background: #fff0ec;
    padding: 30px;
    border-top: 2px solid white;
  }
  main {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0 3px $fog;
    header {
      height: 48px;
      width: 100%;
      border-top-left-radius: 6px;

      border-top-right-radius: 6px;
      h4 {
        font-size: 24px;
        margin-right: 8px;
      }
    }
    .done-section {
      position: relative;
      padding: 48px 30px 48px 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $mist;
      background: white;

      button {
        width: 200px !important;
      }
    }
    .main {
      width: 100%;
      background-color: white;
      padding: 0px;

      h5 {
        font-size: 20px;
        margin-bottom: 20px !important;
        p {
          font-size: 16px;
        }
      }

      .pool {
        padding: 32px 30px 48px 30px;

        &:not(:first-of-type) {
          border-top: 2px solid $cloud;
        }
        .round-pool-title {
          font-size: 32px;
          margin-bottom: 0px;
        }
        .pool-utility {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 16px;
          }

          .delete-groups {
            user-select: none;
            cursor: pointer;
            color: $dusk;
          }
        }
        .utility-container {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          .large {
            font-size: 18px;
            width: 100%;

            &.extra-padding {
              padding: 30px 32px 0 32px;
            }
          }

          .change-start-hole {
            margin: 18px 0 0 0;
            padding: 0 32px 0px 32px;
            border-bottom: 0;
            width: 40%;
            justify-content: flex-start;

            p {
              font-size: 16px;
            }

            .StandardBorderedButton {
              margin-top: 18px;
            }

            .newstarthole {
              margin: 18px 32px 0 0;
            }
          }

          .auto-tee-times {
            border-bottom: 0;
            margin-top: 18px;
            padding: 0 32px 0px 32px;
            border-left: 1px solid $blizzard;
            justify-content: space-between;
            width: 60%;

            .default-radio-buttons {
              margin-top: 18px;
            }

            .increment-options {
              width: 30%;
            }

            .sorting-options {
              flex-direction: row;
              margin-top: 32px;
              .StandardBorderedButton {
                margin-top: auto;
              }
            }

            .time-title {
              margin: 0;
            }

            .teetime-asc-desc {
              margin-right: auto;
            }

            p {
              width: 100%;
              font-size: 16px;
            }

            .selector-container {
              margin-top: 18px;
              margin-right: 32px;
            }

            .StandardBorderedButton {
              margin-top: 18px;
            }
          }

          .delete-groups-container {
            width: 100%;
            border-top: 2px solid $sleet;
            position: relative;
            margin-top: 32px;
            padding: 30px 32px 40px 32px;

            ul {
              margin: 0;
              padding-left: 18px;

              li {
                font-size: 16px;
              }
            }

            p {
              font-size: 16px;

              &.large {
                font-size: 18px;
              }
            }

            .delete-sub-title {
              margin-top: 18px;
            }

            .delete-groups {
              position: absolute;
              right: 32px;
              bottom: 40px;
            }
          }
        }
      }

      .groups-container {
        justify-content: flex-start;
        .group-container {
          width: calc(25% - 30px);
          margin-right: 40px;
          max-width: unset;

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
      .add-group {
        width: 30%;
        max-width: 225px;
        margin-bottom: 0;
        button {
          height: 48px;
        }
      }
    }

    .sidebar {
      width: 230px;
      background: $snow;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 70px;
      padding-top: 95px;

      .info-messages {
        margin-bottom: auto;
      }
    }

    .reset {
      .tooltip-styling {
        max-width: 320px;
        p {
          margin-bottom: 32px;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}
</style>

