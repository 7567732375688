<template>
  <section>
    <h3>
      Players not in a group <span> ({{ playersWithoutGroup.length }})</span>
    </h3>
    <p class="sub-header" v-if="playersWithoutGroup.length > 0">
      Select a player’s block to move them to a group.
    </p>

    <div class="players" v-if="playersWithoutGroup.length > 0">
      <div
        class="player"
        v-for="player in playersWithoutGroup"
        :key="player.id"
        @click="selectWaitinglistPlayer(player)"
        :class="{ selected: selectedPlayer.id == player.id }"
      >
        <div class="basic-info">
          <div
            class="left"
            :class="{ 'dnf-dns': player.dnf.isDnf || player.dns.isDns }"
          >
            <div
              class="division dnf-dns"
              v-if="player.dnf.isDnf || player.dns.isDns"
            >
              <template v-if="player.dnf.isDnf">DNF</template>
              <template v-else-if="player.dns.isDns">DNS</template>
            </div>
            <div class="division" v-else>{{ player.division.type }}</div>
          </div>
          <div class="right">
            <p class="first-name">
              <b>{{ player.user.firstName }}</b>
            </p>
            <p class="last-name">{{ player.user.lastName }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TjingClickToolTip from "@/components/UIElements/TjingClickToolTip";

export default {
  name: "ThePlayersWithoutAGroup",
  props: ["eventData", "managedRound", "roundNumber"],
  components: {},
  data() {
    return {
      selectedPlayer: {},
    };
  },
  watch: {
    playersWithoutGroup: function () {
      this.selectedPlayer = {};
    },
  },
  computed: {
    arePlayersSelectable() {
      let arePlayersSelectable = false;

      this.managedRound.pools.forEach((pool) => {
        pool.groups.forEach((group) => {
          if (group.status == "CLOSED") arePlayersSelectable = true;
        });
      });

      return arePlayersSelectable;
    },
    showPlayersWithoutGroup() {
      if (this.playersWithoutGroup.length > 0) {
        return true;
      }

      return false;
    },
    playersWithoutGroup() {
      let playersWithoutGroupInManagedRound = [];
      let playersThatShouldHaveGroup = [];
      let allPlayers = [];
      let allPlayersIds = [];
      let playersWithoutGroup = [];
      // Commented code below is the old logic for this component and
      //should be removed before go live if the new thinking works through testing!
      //if (this.roundNumber == 1) {
      allPlayers = this.eventData.players;

      allPlayers.forEach((foundPlayer) => {
        this.managedRound.pools.forEach((pool) => {
          pool.groups.forEach((group) => {
            group.playerConnections.forEach((player) => {
              if (player.player.user.id == foundPlayer.user.id) {
                allPlayers = allPlayers.filter((userToRemove) => {
                  return userToRemove.user.id != foundPlayer.user.id;
                });
              }
            });
          });
        });
      });

      return allPlayers;
      /*} else {
        this.eventData.rounds.forEach((round) => {
          round.pools.forEach((pool) => {
            if (pool.status == "COMPLETED") {
              pool.groups.forEach((group) => {
                group.playerConnections.forEach((player) => {
                  if (allPlayersIds.includes(player.player.user.id) == false) {
                    allPlayersIds.push(player.player.user.id);
                    allPlayers.push(player.player);
                  }
                });
              });
            }
          });
        });

        allPlayers.forEach((foundPlayer) => {
          this.managedRound.pools.forEach((pool) => {
            pool.groups.forEach((group) => {
              group.playerConnections.forEach((player) => {
                if (player.player.user.id == foundPlayer.user.id) {
                  allPlayers = allPlayers.filter((userToRemove) => {
                    return userToRemove.user.id != foundPlayer.user.id;
                  });
                }
              });
            });
          });
        });
      }
      return allPlayers;*/
    },
    divisions() {
      return this.$store.getters.tourDivisions;
    },
    waitingList() {
      if (this.sortBy == "name") {
        return this.sortByName(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          )
        );
      } else if (this.sortBy == "pdga") {
        return this.sortByPdga(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          )
        );
      } else if (this.sortBy == "rating") {
        return this.sortByRating(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          )
        );
      } else if (this.sortBy == "payment") {
        return this.sortByPayment(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          )
        );
      } else {
        return this.sortByRegistration(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          )
        );
      }
    },
  },
  methods: {
    selectWaitinglistPlayer(player) {
      //if (this.arePlayersSelectable) {
      if (Object.keys(this.selectedPlayer).length === 0) {
        this.selectedPlayer = player;
        this.$emit("playerSelected", player);
      } else if (this.selectedPlayer.id == player.id) {
        this.selectedPlayer = {};
        this.$emit("playerSelected", null);
      } else {
        this.selectedPlayer = player;
        this.$emit("playerSelected", player);
      }
      //}
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.players {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;
}
.player {
  cursor: pointer;
  width: 48%;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px $sleet;
  background: white;
  transition: all ease 0.3s;

  &.selected {
    box-shadow: 0 0 0 2px $ocean, 0 5px 8px 2px rgba(#000, 0.25);
  }
}
.basic-info {
  display: flex;
  .left {
    background: $mist;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;

    &.dnf-dns {
      background: $dusk;

      .division {
        color: white;
      }
    }
  }

  .division {
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    @include Gilroy-Bold;
    font-size: 8px;
    color: $strom;
    text-transform: uppercase;
  }

  .right {
    display: flex;
    flex-direction: column;
    padding: 8px 10px;

    .first-name {
      text-transform: uppercase;
      font-size: 12px;
      margin: 0;
      line-height: 1.4em;
    }
    .last-name {
      font-size: 11px;
      margin: 0;
      line-height: 1.4em;
    }
  }
}
section {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  .sub-header {
    margin-top: 12px;
  }

  h3 {
    @include Gilroy-Bold;
    font-size: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;

    div {
      margin-left: 8px;
    }

    span {
      margin-left: 6px;
      @include Gilroy-Regular;
    }
  }

  p {
    font-size: 14px;
  }

  article {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: white;
    box-shadow: 0 0 0 1px $fog;
    margin-bottom: 8px;
    transition: all ease 0.3s;

    &.selected {
      box-shadow: 0 0 0 2px $ocean;
    }

    .division {
      background-color: $mist;
      width: 16px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      span {
        transform: rotate(-90deg);
        font-size: 8px;
        letter-spacing: 1.6px;
        color: $strom;
      }
    }

    .player {
      height: 48px;
      display: flex;
      align-items: center;
      @include Gilroy-Bold;
      font-size: 14px;
    }

    .tag {
      margin-left: auto;
      margin-right: 12px;
      font-size: 12px;
      background-color: $dusk;
      color: white;
      border-radius: 4px;
      padding: 3px 5px;
      letter-spacing: 1px;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .players {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 32px;
    width: 100%;
  }
  .player {
    width: calc(25% - 15px);
    margin-right: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0 1px $sleet;
    background: white;
    transition: all ease 0.3s;

    &:nth-of-type(4n) {
      margin-right: 0;
    }

    &.selected {
      box-shadow: 0 0 0 2px $ocean, 0 5px 8px 2px rgba(#000, 0.25);
    }
  }
  .basic-info {
    display: flex;
    .left {
      background: $mist;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
    }

    .division {
      transform: rotate(-90deg);
      display: flex;
      align-items: center;
      justify-content: center;
      @include Gilroy-Bold;
      font-size: 11px;
      color: $strom;
      text-transform: uppercase;
    }

    .right {
      display: flex;
      flex-direction: column;
      padding: 8px 0 9px 10px;

      .first-name {
        text-transform: uppercase;
        font-size: 16px;
        margin: 0;
        line-height: 1.4em;
      }
      .last-name {
        font-size: 14px;
        margin: 0;
        line-height: 1.4em;
      }
    }
  }
  main {
    section {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      h3 {
        width: 100%;
        font-size: 32px;
        margin-bottom: 0;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
      article {
        max-width: calc(50% - 10px);
      }
    }
  }
}
</style>
