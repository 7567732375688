<template>
  <div
    class="group-container"
    :class="{
      selected: selectedGroup != null && group.id == selectedGroup.id,
      'shorter-menu':
        group.status == 'CLOSED' &&
        pool.startMethod == 'TEETIME' &&
        pool.status == 'OPEN',
    }"
    v-click-outside="close"
  >
    <div
      class="header"
      :class="{
        'menu-open': showGroupMenu,
        clickable: group.status == 'CLOSED',
      }"
      @click="
        $emit('select-group', group);
        showMarshallField = false;
      "
    >
      <p>Hole {{ holeDisplayName(group.startHole) }}</p>
      <div class="bullet-container" @click.stop="toggleGroupMenu()">
        <div
          class="edit-bullets"
          :class="{ opened: showGroupMenu, disabled: group.status == 'DONE' }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="displayed-time" v-if="group.startsAt != null">
      <p>Start: {{ startTime }}</p>
      <span @click="updateStartTime(null)" v-if="group.status == 'CLOSED'">
        <Remove />
      </span>
    </div>
    <div class="current-marshall" v-if="group.marshall != null">
      <div class="label">
        <span>Marshall</span>
      </div>
      <div class="name">
        <p>{{ group.marshall.firstName }}</p>
        <span>{{ group.marshall.lastName }}</span>
      </div>

      <span @click="removeMarshall()" class="remove-marshall">
        <Remove />
      </span>
    </div>
    <div class="group-menu" :class="{ open: showGroupMenu }">
      <div class="tee-time" v-if="group.status == 'CLOSED'">
        <div
          class="timePlaceholder"
          v-if="showAddTimePlaceholder"
          @click="showAddTimePlaceholder = false"
        >
          Add start time
        </div>
        <div class="edit-time" v-else>
          <span>
            <input
              type="number"
              v-model="startHour"
              class="left"
              @keypress.enter="updateStartTime(false)"
            />
            :
            <input
              type="number"
              v-model="startMinute"
              class="right"
              @keypress.enter="updateStartTime(false)"
            />
          </span>

          <p @click.stop="updateStartTime(false)">Save</p>
        </div>
      </div>
      <div class="starting-hole" v-if="group.status == 'CLOSED'">
        <p>Start hole:</p>
        <select
          v-model="group.startHole.id"
          @change="updateStartHole($event.target.value)"
        >
          <option :value="hole.id" v-for="hole in pool.layoutVersion.holes">
            {{ holeDisplayName(hole) }}
          </option>
        </select>
      </div>
      <div
        class="marshall"
        v-if="group.marshall == null && group.status != 'DONE'"
      >
        <template v-if="showMarshallError">
          <div class="marshall-error" @click.stop="removeMarshallError()">
            <Error />
            <span>Not found</span>
            <span class="save">Retry</span>
          </div>
        </template>
        <template v-else-if="group.marshall == null">
          <input
            ref="marshall"
            @focus="$event.target.placeholder = `User's email`"
            @blur="$event.target.placeholder = `Add Marshall`"
            type="text"
            placeholder="Add Marshall"
            v-model="marshallEmail"
            @keypress.enter="addMarshall()"
          />
          <div
            class="save"
            @click.stop="addMarshall()"
            v-if="isEmail(marshallEmail)"
          >
            Save
          </div>
        </template>
      </div>
      <div
        class="delete-group"
        @click="deleteGroup(group)"
        v-if="groupIsDeletable"
      >
        Delete group
      </div>
    </div>
    <div
      class="player"
      v-for="player in group.playerConnections"
      :key="player.id"
      :class="{
        selected: selectedPlayer && selectedPlayer.id == player.id,
        tapped: tapNotification == player.id && selectedPlayer.id != player.id,
        tappable: group.status == 'CLOSED',
        'menu-open': expandedPlayerMenu == player.id,
      }"
    >
      <div class="division signed" v-if="player.hasSignedResult">
        <span>Signed</span>
      </div>
      <div
        class="division dnf-dns"
        v-else-if="player.player.dnf.isDnf || player.player.dns.isDns"
      >
        <span v-if="player.player.dnf.isDnf">DNF</span>
        <span v-else-if="player.player.dns.isDns">DNS</span>
      </div>
      <div class="division" v-else>
        <span>{{ player.player.division.type }}</span>
      </div>

      <div
        class="name"
        v-hammer:press="(event) => toggleExpandedPlayerMenu(player.id)"
        v-hammer:tap="(event) => selectPlayer(player)"
      >
        {{ player.player.user.firstName }}
        <span>{{ player.player.user.lastName }}</span>
      </div>

      <div
        class="right-side"
        v-hammer:tap="(event) => toggleExpandedPlayerMenu(player.id)"
      >
        <Chevron2
          class="chevron"
          :direction="expandedPlayerMenu == player.id ? 'up' : 'down'"
        />
      </div>

      <slide-up-down
        tag="ul"
        class="menu"
        :active="expandedPlayerMenu == player.id"
        :duration="300"
      >
        <li
          @click.stop="sendToWaitingList(player)"
          v-if="isEventReallyStarted == false"
        >
          Send to waiting list
        </li>
        <li
          @click.stop="sendToCancelledList(player)"
          v-if="isEventReallyStarted == false"
        >
          Send to cancelled list
        </li>
        <li
          class="sign"
          v-if="
            group.status == 'OPEN' &&
            isResultSigningEnabled &&
            !player.hasSignedResult
          "
          @click="signScoreConfirmation(player)"
        >
          Mark as signed
        </li>
        <template v-if="roundNumber == 1">
          <li
            @click.stop="togglePlayerDNS(player.player)"
            v-if="player.player.dns.isDns"
          >
            Undo DNS
          </li>
          <li @click.stop="togglePlayerDNS(player.player)" v-else>DNS</li>
        </template>
        <li
          class="dnf remove"
          @click.stop="togglePlayerDNF(player.player)"
          v-if="player.player.dnf.isDnf"
        >
          Undo DNF
        </li>
        <li class="dnf" @click.stop="togglePlayerDNF(player.player)" v-else>
          DNF
        </li>

        <li
          @click.stop="removePlayerFromGroup(player)"
          v-if="isEventReallyStarted == true && group.status == 'CLOSED'"
        >
          Remove from group
        </li>
      </slide-up-down>
    </div>
    <div
      class="player"
      v-for="emptyPlayers in 5 - group.playerConnections.length"
      :key="emptyPlayers"
      :class="{
        tapped: tapNotification == emptyPlayers,
        'empty-spot-disabled': group.status != 'CLOSED',
        empty: selectedPlayer != null,
      }"
      v-hammer:tap="(event) => emptySpotClicked(emptyPlayers)"
    >
      <div class="division none">
        <span></span>
      </div>
      <div></div>
      <span></span>
    </div>

    <div
      class="footer prepare start-manually"
      @click="startGroup(group)"
      v-if="
        group.status == 'CLOSED' &&
        pool.startMethod == 'TEETIME' &&
        pool.status == 'OPEN'
      "
    >
      <p class="title">Start group</p>
    </div>
    <div class="footer prepare" v-else-if="group.status == 'CLOSED'">
      <p class="title">Upcoming</p>
      <span></span>
    </div>
    <div
      class="footer unsigned"
      v-else-if="
        group.status == 'OPEN' &&
        isResultSigningEnabled &&
        showUnsignedBadge.length > 0
      "
    >
      <p class="title">{{ showUnsignedBadge.length }} Unsigned</p>
      <span></span>
    </div>
    <div class="footer ongoing" v-else-if="group.status == 'OPEN'">
      <p class="title">Live</p>
      <span></span>
    </div>

    <div class="footer done" v-else-if="group.status == 'DONE'">
      <p class="title">Finished</p>
      <MidnightCheckmark />
    </div>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="signscore"
      hide-close-button
    >
      <template v-if="playerToSignScoreFor != null">
        <h3>Sign for {{ playerToSignScoreFor.player.user.firstName }}?</h3>
        <p>This cannot be undone.</p>

        <div class="buttons">
          <StandardSolidButton
            title="Cancel"
            :fluid="false"
            @click="cancelSigning()"
          />
          <StandardBorderedButton
            class="forwards"
            title="Sign"
            :fluid="false"
            @click="signResult(playerToSignScoreFor)"
          />
        </div>
      </template>
    </sweet-modal>
  </div>
</template>

<script>
import moment from "moment";
import ClickOutside from "vue-click-outside";
import validator from "validator";
import MidnightCheckmark from "@/assets/icons/MidnightCheckmark";
import Remove from "@/assets/icons/Remove";
import Error from "@/assets/icons/Error";
import SlideUpDown from "vue-slide-up-down";
import Chevron2 from "@/assets/icons/Chevron2";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";

export default {
  name: "StandardEditableGroup",
  props: [
    "pool",
    "group",
    "isResultSigningEnabled",
    "selectedPlayer",
    "selectedGroup",
    "roundNumber",
    "startMethod",
    "allowStartTime",
    "isEventReallyStarted",
  ],

  directives: {
    ClickOutside,
  },
  components: {
    MidnightCheckmark,
    Remove,
    Error,
    SlideUpDown,
    Chevron2,
    StandardBorderedButton,
    StandardSolidButton,
  },
  watch: {
    group: {
      deep: true,
      handler() {
        if (this.group.marshall == null) {
          this.showMarshallField = false;
          this.marshallEmail = "";
        }
      },
    },
  },
  data() {
    return {
      showGroupMenu: false,
      holeListExpanded: false,
      expandedPlayerMenu: "",
      marshallEmail: "",
      showMarshallField: false,
      tapNotification: null,
      startHour: 0,
      startMinute: 0,
      showMarshallError: false,
      showAddTimePlaceholder: true,
      showVerifyRemovePlayer: false,
      playerToSignScoreFor: null,
    };
  },
  computed: {
    groupIsDeletable() {
      let groupIsDeletable = true;

      if (this.group.playerConnections.length > 0) groupIsDeletable = false;
      if (this.group.status == "OPEN" || this.group.status == "DONE")
        groupIsDeletable = false;

      return groupIsDeletable;
    },
    showUnsignedBadge() {
      let badgesToShow = [];
      let atleastOneSigned = false;

      if (this.group.status == "OPEN") {
        this.group.playerConnections.forEach((player) => {
          if (player.hasSignedResult == false) {
            badgesToShow.push(player.player.id);
          } else {
            atleastOneSigned = true;
          }
        });
      }

      return atleastOneSigned ? badgesToShow : [];
    },
    startTime() {
      return moment(this.group.startsAt).format("HH:mm");
    },
  },
  methods: {
    toggleGroupMenu() {
      if (this.group.status != "DONE") {
        this.showGroupMenu = !this.showGroupMenu;
      }
    },
    removeMarshallError() {
      this.showMarshallError = false;
      this.$nextTick(() => {
        this.$refs.marshall.focus();
      });
    },
    cancelSigning() {
      this.playerToSignScoreFor = null;
      this.$refs.signscore.close();
    },
    signScoreConfirmation(player) {
      this.playerToSignScoreFor = player;
      this.$refs.signscore.open();
    },
    async deleteGroup(group) {
      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              DeleteGroup(groupId:"${group.id}"){
                id
              }
            }`,
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Group deleted",
          type: "success",
        });
        this.$emit("refresh");
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error deleting group",
          type: "error",
        });
        this.$emit("refresh");
      }
    },
    async updateStartHole(holeId) {
      try {
        let groupStartHole = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation UpdateGroup($UpdateGroup: UpdateGroupInput!){
            UpdateGroup(groupId:"${this.group.id}",input:$UpdateGroup){
              id
              startsAt
            }
          }
          `,
            variables: {
              UpdateGroup: {
                startHoleId: holeId,
              },
            },
          },
        });
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
        this.showGroupMenu = false;
        this.$emit("refresh");
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
        this.$emit("refresh");
      }
    },
    async signResult(player) {
      try {
        let removePlayer = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation {
            SignResult(groupId:"${this.group.id}",playerId:"${player.player.id}",asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: player.player.user.firstName + " result signed",
          type: "success",
        });
        this.playerToSignScoreFor = null;
        this.$emit("refresh");
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async startGroup(group) {
      try {
        let marshall = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation {
            UpdateGroupStatus(groupId:"${group.id}",status:OPEN){
              id
            }
          }
          `,
          },
        });
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Started",
          type: "success",
        });
        this.$emit("refresh");
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    isEmail(email) {
      if (validator.isEmail(email)) {
        return true;
      }
      return false;
    },
    async updateStartTime(reset) {
      let groupTime = "";

      if (reset == null) {
        this.group.startsAt = null;
        groupTime = null;
        this.showAddTimePlaceholder = true;
      } else {
        this.group.startsAt = moment(this.pool.date)
          .hour(this.startHour)
          .minute(this.startMinute);

        groupTime = this.group.startsAt.toISOString();
      }

      try {
        let groupStartTime = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation UpdateGroup($UpdateGroup: UpdateGroupInput!){
            UpdateGroup(groupId:"${this.group.id}",input:$UpdateGroup){
              id
              startsAt
            }
          }
          `,
            variables: {
              UpdateGroup: {
                startsAt: groupTime,
              },
            },
          },
        });
        this.showGroupMenu = false;
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async togglePlayerDNF(player) {
      if (player.dns.isDns) {
        await this.togglePlayerDNS(player);
      }

      try {
        let marshall = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation{
            UpdatePlayerDnf(playerId:"${player.id}",isDnf:${!player.dnf
              .isDnf},asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });

        this.$emit("refresh");
        this.expandedPlayerMenu = "";
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async togglePlayerDNS(player) {
      if (player.dnf.isDnf) {
        await this.togglePlayerDNF(player);
      }
      try {
        let marshall = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation{
            UpdatePlayerDns(playerId:"${player.id}",isDns:${!player.dns
              .isDns},asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
        this.expandedPlayerMenu = "";
        this.$emit("refresh");
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async removePlayerFromGroup(player) {
      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              RemovePlayerFromGroup(groupId:"${this.group.id}",playerId:"${player.player.id}"){
                id
              }
            }
            `,
          },
        });
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Player removed",
          type: "success",
        });
        this.$emit("refresh");
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async sendToCancelledList(player) {
      let registrations = this.$parent.eventData.registrations.filter(
        (registration) => registration.player != null
      );
      let registrationId = registrations.filter(
        (registration) => registration.player.id == player.player.id
      )[0].id;

      try {
        let sendPlayer = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation{
            UpdateRegistrationStatus(registrationId:"${registrationId}",status:USER_CANCELLED){
              id
            }
          }
          `,
          },
        });
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Player updated",
          type: "success",
        });
        this.$emit("refresh");
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async sendToWaitingList(player) {
      let registrations = this.$parent.eventData.registrations.filter(
        (registration) => registration.player != null
      );
      let registrationId = registrations.filter(
        (registration) => registration.player.id == player.player.id
      )[0].id;

      try {
        let sendPlayer = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation{
            UpdateRegistrationStatus(registrationId:"${registrationId}",status:PENDING){
              id
            }
          }
          `,
          },
        });
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Player updated",
          type: "success",
        });
        this.$emit("refresh");
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async removeMarshall() {
      try {
        let marshall = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation {
            RemoveGroupMarshall(groupId:"${this.group.id}"){
              marshall{
                firstName
                lastName
              }
            }
          }
          `,
          },
        });
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Marshall removed",
          type: "success",
        });
        this.showGroupMenu = false;
        this.$emit("refresh");
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async addMarshall() {
      try {
        let marshall = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation {
            AddGroupMarshall(groupId:"${this.group.id}",email:"${this.marshallEmail}"){
              marshall{
                firstName
                lastName
              }
            }
          }
          `,
          },
        });

        if (marshall.data.errors) {
          this.showMarshallError = true;
        } else {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Marshall added",
            type: "success",
          });
          this.showGroupMenu = false;
          this.$emit("refresh");
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    flashTapNotification(id) {
      this.tapNotification = id;
      setTimeout(() => {
        this.tapNotification = null;
      }, 100);
    },
    holeDisplayName(hole) {
      if (hole.name != null) {
        return hole.name;
      } else {
        return hole.number;
      }
    },
    emptySpotClicked(id) {
      this.$emit("emptySpotClicked", this.group.id);
      this.flashTapNotification(id);
    },
    selectPlayer(player) {
      if (this.group.status == "CLOSED") {
        if (
          this.selectedPlayer != null &&
          this.selectedPlayer.id == player.id
        ) {
          this.$emit("selectPlayer", null);
        } else if (
          this.selectedPlayer != null &&
          this.selectedPlayer.id != player.id
        ) {
          this.flashTapNotification(player.id);
          this.$emit("swapPlayers", player);
        } else {
          this.flashTapNotification(player.id);
          this.$emit("selectPlayer", player);
        }
      }
    },
    close() {
      this.expandedPlayerMenu = "";
    },
    toggleExpandedPlayerMenu(playerId) {
      if (this.expandedPlayerMenu == playerId) {
        this.expandedPlayerMenu = "";
        this.$emit("selectPlayer", null);
      } else {
        this.expandedPlayerMenu = playerId;
        this.$emit("selectPlayer", null);
      }
    },
  },
  mounted() {
    if (this.group.marshall != null) {
      this.showMarshallField = true;
    }
    if (this.group.startsAt == null) {
      this.startHour = moment(
        moment(this.pool.date).add(10 * this.group.position, "minutes")
      ).hour();
      this.startMinute = moment(this.pool.date)
        .add(10 * this.group.position, "minutes")
        .minute();
    } else {
      this.startHour = moment(this.group.startsAt).hour();
      this.startMinute = moment(this.group.startsAt).minute();
      this.showAddTimePlaceholder = false;
    }
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.group-container {
  width: calc(50% - 6px);
  margin-bottom: 38px;
  border: 1px solid transparent;
  position: relative;
  transition: all ease 0.3s;
  border-radius: 6px;

  &.ongoing {
    .player {
      cursor: default;
    }
    .header {
      cursor: default;
    }
  }

  &.selected {
    border: 1px solid $ocean;

    box-shadow: 0 0 0 1px $ocean, 0 5px 8px 1px rgba(#000, 0.25);
  }

  &.shorter-menu {
    .group-menu {
      &.open {
        bottom: 41px;
      }
    }
  }

  .group-menu {
    position: absolute;
    top: 33px;
    left: 1px;
    right: 1px;
    bottom: 100%;
    background: white;
    z-index: 2;
    transition: all ease 0.5s;
    overflow: hidden;

    &.open {
      bottom: 32px;
    }
  }
}
.bullet-container {
  cursor: pointer;
  padding: 14px 10px 14px 10px;
}
.edit-bullets {
  width: 18px;
  height: 2px;
  position: relative;

  &.disabled {
    cursor: default;
    span {
      background: $sleet;
    }
  }

  span {
    width: 4px;
    height: 4px;
    background-color: $midnight;
    display: flex;
    border-radius: 50%;
    position: absolute;
    transition-delay: 0.1s;
    transition: 0.2s all ease-in-out;
  }

  span:nth-child(1) {
    right: 14px;
    top: 0px;
  }
  span:nth-child(2) {
    right: 7px;
    top: 0px;
  }
  span:nth-child(3) {
    right: 0;
    top: 0px;
  }

  &.opened {
    span:nth-child(1) {
      width: 16px;
      height: 2px;
      border-radius: 0;
      right: 1px;
      transform: rotate(-45deg);
      transition-delay: 0s;
      top: 1px;
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      width: 16px;
      height: 2px;
      border-radius: 0;
      right: 1px;
      transform: rotate(45deg);
      transition-delay: 0s;
      top: 1px;
    }
  }
}

.delete-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  @include Gilroy-Bold;
  cursor: pointer;
  font-size: 14px;
  padding-left: 10px;
  height: 44px;
  background-color: white;
  font-size: 14px;
  color: $dusk;

  &:hover {
    background: $mist;
  }
}

.tee-time {
  height: 44px;
  background-color: white;
  font-size: 16px;
  @include Gilroy-Bold;
  line-height: 16px;
  transition: all ease 0.3s;

  &:hover {
    background: $mist;
  }

  .timePlaceholder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    @include Gilroy-Bold;
    cursor: pointer;
    font-size: 14px;
    padding-left: 10px;
  }

  .edit-time {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 10px;
    p {
      margin: 0;
      font-size: 14px;
      color: $twilight;
      cursor: pointer;
      @include Gilroy-Bold;
      margin-left: auto;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
    }
    .check {
      height: 24px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      width: 32px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid $blizzard;
      font-size: 14px;
      @include Gilroy-Bold;
      height: 32px;
      outline: none;

      &.left {
        margin-right: 5px;
      }
      &.right {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}
.displayed-time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 44px;
  padding: 0 12px 0 10px;
  border-right: 1px solid $fog;
  border-left: 1px solid $fog;
  background: $mist;
  p {
    margin: 0;
    font-size: 14px;
    @include Gilroy-Bold;
  }
  span {
    display: flex;
    height: 12px;
    align-items: center;
    margin-left: auto;
  }
  svg {
    height: 12px;
    width: 12px;
    cursor: pointer;
  }
}

.header {
  background-color: $fog;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 32px;
  color: $midnight;
  display: flex;
  justify-content: space-between;
  transition: all ease 0.3s;
  align-items: center;
  padding: 0px 0px 0 10px;

  &.clickable {
    cursor: pointer;
  }

  &.menu-open {
    background: $sleet;
  }
  p {
    @include Gilroy-Bold;
    font-size: 14px;
    margin: 0;
    color: $midnight;
  }

  span {
    color: $teal;
    @include Gilroy-Medium;
    font-size: 10px;
  }
}
.starting-hole {
  display: flex;
  height: 44px;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  transition: all ease 0.3s;

  &:hover {
    background: $mist;
  }

  p {
    font-size: 14px;
    margin: 0;
    @include Gilroy-Bold;
  }

  select {
    height: 32px;
    width: 64px;
    background-position: calc(100% - 8px);
    background-size: 10px;
    font-size: 14px;
    padding: 0 8px;
    margin: 0;
    border: 1px solid $blizzard;
  }
}

.current-marshall {
  background-color: #f9f2e3;
  border-top: 1px solid $fog;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 0 12px 0 0px;
  font-size: 12px;
  border-left: 1px solid $fog;
  border-right: 1px solid $fog;

  .icon {
    width: 20px;
    height: 24px;
    margin-right: 10px;
  }

  .remove-marshall {
    display: flex;
    height: 12px;
    align-items: center;
    margin-left: auto;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  .name {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    p {
      text-transform: uppercase;
      margin: 0;
      color: $midnight;
      font-size: 12px;
      @include Gilroy-Bold;
    }
    span {
      font-size: 11px;
      color: $midnight;
      @include Gilroy-Regular;
    }
  }
  .label {
    width: 16px;
    min-width: 16px;
    background-color: $gold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    cursor: default;
    user-select: none;
    transition: all ease 0.3s;

    &.none {
      background-color: transparent;
    }

    span {
      display: flex;
      transform: rotate(-90deg);
      font-size: 8px;
      justify-content: center;
      align-items: center;
      @include Gilroy-Bold;
      letter-spacing: 0px;
      text-transform: uppercase;
      color: white;
      letter-spacing: 0.25px;
    }
  }
}
.marshall {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  transition: all ease 0.3s;
  height: 44px;

  &:hover {
    input {
      background: $mist;
    }
  }

  .marshall-error {
    padding: 0 10px;
    background-color: #f9f2e3;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    @include Gilroy-Bold;

    svg {
      width: 16px;
      margin-bottom: 2px;
      margin-right: 10px;
    }

    .save {
      margin-left: auto;
      padding: 0;
    }
  }

  .save {
    padding: 0 10px 0 0px;
    color: $twilight;
    font-size: 14px;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    @include Gilroy-Bold;
    height: 100%;
    background-color: #f9f2e3;
  }
  input {
    padding: 0 10px;
    font-size: 14px;
    height: 44px;
    font-size: 14px;
    @include Gilroy-Bold;
    margin: 0;
    border: none;
    width: 100%;
    color: $midnight;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all ease 0.3s;

    &::placeholder {
      font-size: 14px;
      @include Gilroy-Bold;
      color: $midnight;
    }
    &:focus {
      background: #f9f2e3;
      outline: none;
      &::placeholder {
        color: $gold;
      }
    }
  }
}

.player {
  border: 1px solid $fog;
  border-bottom: 0px;
  min-height: 48px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: all ease-in-out 0.25s;
  box-sizing: border-box;
  position: relative;
  transition: all ease 0.3s;

  &.menu-open {
    background: $mist;
  }

  &.tappable {
    cursor: pointer;
  }

  &.empty-spot-disabled {
    cursor: default;
  }

  &.empty:hover {
    background-color: $mist;
  }

  &.tapped {
    background-color: $halfmouse;
    transition: all ease 0.25s;
  }

  &.selected {
    box-shadow: 0 5px 8px 2px rgba(#000, 0.15);
    border-left: 1px solid $ocean;
    border-right: 1px solid $ocean;
    border-top: 1px solid $ocean;

    + div {
      border-top: 1px solid $ocean;
    }

    transition: all ease 0.3s;

    .division {
      transition: all ease 0.3s;
    }
  }

  .edit {
    font-size: 10px;
    color: $teal;
    @include Gilroy-Medium;
    width: 32px;
    text-align: right;
    cursor: pointer;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .division {
    width: 16px;
    min-width: 16px;
    background-color: $mist;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    cursor: default;
    user-select: none;
    transition: all ease 0.3s;

    &.none {
      background-color: transparent;
    }

    span {
      display: flex;
      transform: rotate(-90deg);
      font-size: 9px;
      justify-content: center;
      align-items: center;
      @include Gilroy-Bold;
      letter-spacing: 0.9px;
      text-transform: uppercase;
      color: $blizzard;
    }
    &.signed {
      span {
        letter-spacing: 0.45;
        color: white;
      }

      background-color: $sky;
    }
    &.dnf-dns {
      span {
        color: white;
      }

      background-color: $dusk;
    }
  }

  .name {
    font-size: 12px;
    text-transform: uppercase;
    @include Gilroy-Bold;
    padding-left: 10px;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 50px);
    width: 100%;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    line-height: 16px;

    span {
      text-transform: none;
      display: flex;
      align-items: center;
      width: 100%;
      @include Gilroy-Regular;
      font-size: 11px;
      line-height: 16px;
    }
  }

  .right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 13px 0 6px;
    height: 44px;
    cursor: pointer;

    .chevron {
      width: 10px;
    }

    .signed {
      height: 18px;
    }
  }

  .menu {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      background-color: white;
      transition: all ease 0.1s;
      height: 44px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      @include Gilroy-Bold;
      font-size: 14px;
      position: relative;

      &:hover {
        background-color: $mist;
        cursor: pointer;
      }

      &.dnf {
        color: $dusk;

        &.remove {
          color: $midnight;
        }
      }
    }
  }
}

.footer {
  background-color: $fog;
  letter-spacing: 0.3px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 24px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-right: 10px;
  p {
    margin: 0;
    font-size: 10px;
    color: $strom;
    @include Gilroy-Bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  span {
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 6px;
  }

  &.prepare {
    span {
      background-color: $sunrise;
    }
    &.start-manually {
      background-color: $twilight;
      cursor: pointer;
      height: 40px;
      justify-content: center;
      padding-right: 0;
      transition: all ease 0.3s;

      &:hover {
        background-color: $twilightshade;
        cursor: pointer;
      }
      .title {
        font-size: 16px;
        @include Gilroy-Bold;
        color: white;
        text-transform: none;
      }
    }
  }

  &.unsigned {
    span {
      background-color: $dusk;
    }
  }

  &.ongoing {
    span {
      background-color: $grass;
    }
  }

  &.done {
    svg {
      width: 12px;
      height: 12px;
      margin-left: 6px;
    }
  }
  span {
    display: flex;
    align-items: center;
    font-size: 10px;
    justify-content: flex-end;
    padding-right: 10px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .bullet-container {
    padding: 18px 12px 18px 10px;
  }
  .edit-bullets {
    width: 18px;
    height: 2px;
    cursor: pointer;
    position: relative;

    span {
      width: 5px;
      height: 5px;
      background-color: $midnight;
      display: flex;
      border-radius: 50%;
      position: absolute;
      transition-delay: 0.1s;
      transition: 0.2s all ease-in-out;
    }

    span:nth-child(1) {
      right: 16px;
      top: 0px;
    }
    span:nth-child(2) {
      right: 8px;
      top: 0px;
    }
    span:nth-child(3) {
      right: 0;
      top: 0px;
    }
  }
  .delete-group {
    height: 48px;
    padding-left: 12px;
    font-size: 16px;
  }
  .tee-time {
    .timePlaceholder {
      height: 48px;
      padding-left: 12px;
      font-size: 16px;
    }
  }
  .starting-hole {
    height: 48px;
    padding: 0 12px;
    p {
      font-size: 16px;
    }
    select {
      font-size: 16px;
      background-size: 14px;
      padding: 0 10px;
    }
  }
  .group-container {
    width: 30%;
    max-width: 225px;
    margin-bottom: 44px;
    position: relative;
    .group-menu {
      top: 41px;
    }
    &.selected {
      border-radius: 6px;
    }

    .header {
      padding: 0 0px 0 12px;
      height: 40px;

      p {
        font-size: 16px;
      }
    }
    .displayed-time {
      padding: 0 13px 0 12px;
      p {
        font-size: 16px;
      }
      span {
        display: flex;
        height: 16px;
        align-items: center;
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
    .tee-time {
      .edit-time {
        height: 48px;
        padding-left: 12px;
        span {
          justify-content: flex-start;
          input {
            width: 48px;

            &.left {
              margin-right: 8px;
            }
            &.right {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .player {
      .division {
        height: 58px;
        width: 23px;
        span {
          font-size: 11px;
        }
      }
      .name {
        width: calc(100% - 75px);
        font-size: 16px;
        line-height: 1.4em;
        span {
          font-size: 14px;
        }
      }
      .right-side {
        padding-left: 12px;
        .chevron {
          width: 16px;
        }
        .dnf-dns {
          font-size: 10px;
        }
      }
      .menu {
        li {
          font-size: 16px;
          padding-left: 10px;
          height: 48px;
        }
      }
    }
    .footer {
      height: 32px;
      line-height: unset;
      p {
        font-size: 12px;
      }
    }
  }
  .current-marshall {
    font-size: 16px;
    height: 58px;
    padding: 0 13px 0 0px;

    .label {
      height: 58px;
      width: 23px;
      span {
        font-size: 9px;
        letter-spacing: 0.5px;
      }
    }
    .name {
      p {
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.4em;
      }
      span {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .remove-marshall {
      height: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .marshall {
    height: 48px;
    input {
      height: 48px;
      font-size: 16px;
      padding-left: 12px;
      &::placeholder {
        font-size: 16px;
      }
    }

    &.visible {
      ::placeholder {
        font-size: 14px;
      }
      input {
        font-size: 14px;
      }

      .save {
        font-size: 12px;
      }
    }

    .marshall-error {
      padding: 0 12px;
      font-size: 16px;
      svg {
        width: 20px;
      }
    }
  }
}
</style>

