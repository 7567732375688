<template>
  <section>
    <h3>
      Players on the waiting list
      <span>({{ waitingList.length }})</span>
    </h3>
    <div class="sub-header" v-if="waitingList.length > 0">
      <p>Select a player’s block to move them to a group.</p>

      <p class="show-details" v-if="showDetails" @click="showDetails = false">
        Hide player details
      </p>
      <p class="show-details" v-else @click="showDetails = true">
        Show player details
      </p>
    </div>

    <div class="players" v-if="waitingList.length > 0">
      <div
        class="player"
        v-for="player in waitingList"
        :key="player.id"
        @click="selectWaitinglistPlayer(player)"
        :class="{ selected: selectedPlayer.id == player.id }"
      >
        <div class="left">
          <div class="division">{{ player.division.type }}</div>
        </div>
        <div class="right">
          <div class="basic-info">
            <p class="first-name">
              <b>{{ player.user.firstName }}</b>
            </p>
            <p class="last-name">{{ player.user.lastName }}</p>
          </div>
          <slide-up-down :active="showDetails" :duration="300">
            <div class="detailed-info">
              <p>
                PDGA:
                {{
                  player.user.profile.pdgaNumber
                    ? player.user.profile.pdgaNumber
                    : "-"
                }}<template v-if="player.user.profile.pdgaRating">
                  <span class="dot-spacer">•</span>Rat:
                  {{ player.user.profile.pdgaRating }}
                </template>
              </p>
              <p>
                Registered:
                <b>{{ registeredFormatted(player.createdAt, true) }}</b>
              </p>
              <div class="paid">
                <p>Paid:</p>
                <On v-if="player.hasPaid" />
                <Off v-else />
              </div>
            </div>
          </slide-up-down>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import SlideUpDown from "vue-slide-up-down";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";

export default {
  name: "GroupEditorWaitingList",
  props: ["eventData", "showWaitingList", "columnsToShow"],
  components: { SlideUpDown, On, Off },
  data() {
    return {
      waitinglistSortBy: "createdAt",
      playerSortBy: "",
      selectedPlayer: {},
      sortBy: "registration",
      sortAscending: false,
      showDetails: false,
    };
  },
  watch: {
    waitingList: function () {
      this.selectedPlayer = {};
    },
  },
  computed: {
    shownColumns() {
      return this.columnsToShow;
    },
    divisions() {
      return this.$store.getters.tourDivisions;
    },
    waitingList() {
      return this.sortByRegistration(
        this.eventData.registrations.filter(
          (registration) => registration.status == "PENDING"
        )
      );
    },
  },
  methods: {
    sortByRegistration(arrayToSort) {
      if (!this.sortAscending) {
        return arrayToSort.sort(
          (a, b) => moment(a.createdAt) - moment(b.createdAt)
        );
      } else {
        return arrayToSort.sort(
          (a, b) => moment(b.createdAt) - moment(a.createdAt)
        );
      }
    },
    registeredFormatted(date, full) {
      if (full) {
        return moment(date).format("D MMM [at] HH:mm");
      } else {
        return moment(date).format("D MMMM");
      }
    },
    selectWaitinglistPlayer(player) {
      if (Object.keys(this.selectedPlayer).length === 0) {
        this.selectedPlayer = player;
        this.$emit("playerInWaitingSelected", player);
      } else if (this.selectedPlayer.id == player.id) {
        this.selectedPlayer = {};
        this.$emit("playerInWaitingSelected", null);
      } else {
        this.selectedPlayer = player;
        this.$emit("playerInWaitingSelected", player);
      }
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.players {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  justify-content: space-between;
}
.player {
  width: 48%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 0 1px $sleet;
  background: white;
  transition: all ease 0.3s;
  cursor: pointer;

  &.selected {
    box-shadow: 0 0 0 2px $ocean, 0 5px 8px 2px rgba(#000, 0.25);
  }
}
.left {
  background: $mist;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;

  .division {
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    @include Gilroy-Bold;
    font-size: 8px;
    color: $strom;
    text-transform: uppercase;
  }
}
.basic-info {
  display: flex;
  flex-direction: column;
  padding: 6px 0px 6px 10px;
  .first-name {
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
    line-height: 1.4em;
  }
  .last-name {
    font-size: 11px;
    margin: 0;
    line-height: 1.4em;
  }
}
.detailed-info {
  display: flex;
  border-top: 1px solid $mist;
  flex-direction: column;
  padding: 6px 0px 6px 0px;
  margin-left: 10px;

  p {
    margin: 0;
    font-size: 11px;
    line-height: 1.4em;
    b {
      @include Gilroy-Bold;
    }
  }

  .paid {
    display: flex;
    align-items: center;

    svg {
      margin-left: 2px;
      height: 10px;
      width: 10px;
    }
  }
}
.dot-spacer {
  padding: 0 4px;
}

section {
  border-bottom: 1px solid $fog;
  padding-bottom: 32px;
  h3 {
    font-size: 24px;
    @include Gilroy-Bold;
    margin-bottom: 0;
    span {
      @include Gilroy-Regular;
    }
  }
  p {
    font-size: 14px;
  }

  .sub-header {
    p {
      margin: 10px 0;
    }
    .show-details {
      cursor: pointer;
      color: $ocean;
      user-select: none;
      margin-top: 18px;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .dot-spacer {
    padding: 0 8px;
  }
  .players {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 32px;
  }
  .player {
    width: calc(25% - 15px);
    margin-right: 20px;
    margin-bottom: 10px;
    transition: all ease 0.3s;

    &:nth-of-type(4n) {
      margin-right: 0;
    }

    &.selected {
      box-shadow: 0 0 0 2px $ocean, 0 5px 8px 2px rgba(#000, 0.25);
    }
  }
  .left {
    width: 22px;
    .division {
      font-size: 11px;
    }
  }
  .right {
    width: 100%;
  }
  .basic-info {
    padding: 8px 0 9px 10px;
    .first-name {
      font-size: 16px;
    }
    .last-name {
      font-size: 14px;
    }
  }
  .detailed-info {
    padding: 8px 0 9px 0px;
    margin-right: 10px;
    p {
      font-size: 14px;
    }
    .paid {
      svg {
        width: 12px;
        height: 12px;
        margin-left: 4px;
      }
    }
  }

  section {
    border-bottom: none;
    padding-bottom: 48px;

    h3 {
      font-size: 32px;
      margin-bottom: 0px;
    }

    .sub-header {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;

      .show-details {
        color: $ocean;
        user-select: none;
      }
    }
    p {
      font-size: 16px;
    }
  }
}
</style>
