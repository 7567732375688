<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24">
    <path
      class="a"
      d="M12.272,2.962a2,2,0,0,1,3.455,0L26.245,20.992A2,2,0,0,1,24.518,24H3.482a2,2,0,0,1-1.728-3.008Z"
    />
    <g transform="translate(-49.75 -328.5)">
      <path class="b" d="M-.5,0H2L1.583,7.5H-.083Z" transform="translate(63 337)" />
      <circle class="b" cx="1.25" cy="1.25" r="1.25" transform="translate(62.5 346)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Error"
};
</script>

<style lang="scss" scoped>
.a {
  fill: #ff0064;
}
.b {
  fill: #fff;
}
</style>