<template>
  <main :class="color">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "TjingHorizontalNotification",
  props: ["", "color"],
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
main {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  width: 100%;

  svg {
    margin-right: 8px;
  }

  p {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-bottom: 0;
    line-height: 16px;
  }

  .tooltip-container {
    margin-left: auto;

    .message {
      p {
        color: $midnight;
        @include Gilroy-Regular;
      }
    }
  }

  &.sunrise {
    background-color: $sunrisemist;
    p {
      color: $sunrise;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  main {
    padding: 0 20px;

    p {
      font-size: 16px;
      @include Gilroy-Bold;
      margin-bottom: 0;
      line-height: 16px;
    }

    &.sunrise {
      border: 1px solid $sunrise;
      border-radius: 6px;
    }
  }
}
</style>
