<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g transform="translate(-172 -138)">
      <path class="a" d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z" transform="translate(172 138)" />
    </g>
    <circle class="b" cx="1.25" cy="1.25" r="1.25" transform="translate(6.75 3)" />
    <rect class="b" width="2" height="6" transform="translate(7 6.5)" />
  </svg>
</template>

<script>
export default {
  name: "ToolTip"
};
</script>

<style lang="scss" scoped>
.a {
  fill: #05b5bc;
}
.b {
  fill: #fff;
}
</style>