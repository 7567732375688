<template>
  <div class="tooltip-container" v-click-outside="close">
    <div
      class="icon-container"
      ref="icon"
      id="icon"
      @click="clicked = !clicked"
    >
      <p v-if="iconType == 'text'">{{ title }}</p>
      <Warning v-else-if="iconType == 'warning'" />
      <ToolTip v-else />
    </div>
    <div class="message" v-if="clicked" id="tooltip" ref="tooltip">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import ClickOutside from "vue-click-outside";
import ToolTip from "@/assets/icons/ToolTip";
import Warning from "@/assets/icons/Warning";

export default {
  name: "TjingClickToolTip",
  props: ["position", "message", "iconType", "title"],
  data() {
    return {
      clicked: false,
      popperInstance: null,
    };
  },
  components: {
    ToolTip,
    Warning,
  },
  directives: {
    ClickOutside,
  },
  watch: {
    clicked: function (newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          const button = this.$refs.icon;
          const tooltip = this.$refs.tooltip;

          this.popperInstance = createPopper(button, tooltip, {
            placement: "top",
            modifiers: [
              {
                name: "preventOverflow",
                options: {
                  mainAxis: true,
                  padding: { right: 12, left: 12 },
                },
              },
              {
                name: "offset",
                options: {
                  offset: [0, 15],
                },
              },
            ],
          });
        });
      } else {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
  },
  computed: {},
  methods: {
    close() {
      this.clicked = false;
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.tooltip-container {
  cursor: pointer;
  height: 16px;
  width: 16px;
  display: flex;

  .icon-container {
    display: inline-flex;
    align-items: flex-start;
    height: 16px;
    width: 16px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .message {
    background-color: white;
    border: 2px solid $fog;
    color: $midnight;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px $sleet;
    cursor: default;
    z-index: 40;
    width: auto;
    max-width: 280px;

    a {
      color: $teal;
      @include Gilroy-Regular;
      font-size: 14px !important;

      &:hover {
        text-decoration: underline;
      }
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 1.2rem;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .tooltip-container {
    .message {
      max-width: 350px;
      border-radius: 12px;
    }
  }
}
</style>
